nav{
    width: 100%;
    position: fixed;
    // transform: translateY(100%);
    overflow: hidden;
    top: 0;
    opacity: 0;
    left: 0;
    background-color: #FFFFFF;
    height: 65px;
    z-index: 99999;
    display: flex;
    justify-content: center;
    align-items: center;
    pointer-events: none;
    @include breakpoint("xs"){
        opacity: 1!important;
    }
    &.visible{
        pointer-events: auto;
    }
    &.active{
        overflow: visible;
    }
    
    .wrapper-nav{
        width: 94%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .logo-left{
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }
    .logo{
        width: 80px;
        height: 6px;
    }
    #right-nav{
        display: flex;
        a{
            text-decoration: none;
            color:#000000;
        }
        .ext-links{
            margin-right: 20px;
            @include breakpoint("xs"){
                margin-right: 10px;
            }
            &.only-desktop{
                @include breakpoint("xs"){
                    display: none;
                }
            }
            
        }
        .ext-links, .button-menu{
            position: relative;
            font-family: 'VolvoNovum-Medium', Arial, sans-serif;
            font-weight: 500;
            &::after{
                content: ' ';
                position: absolute;
                bottom: -10px;
                left: 0;
                width: 100%;
                border-bottom: 1px solid transparent;
                transition: .3s ease-in-out;
            }
      
  
            
            &:hover{
                &::after{
                    border-bottom: 1px solid rgb(20,20,20);
                }
            }
        }
    }
    #menu{
        height: 100vh;
        width: 400px;
        position: absolute;
        top: 0;
        right: 0;
        z-index: 160;
        transform: translateX(100%);
        background-color: #FFFFFF;
        transition: .3s ease-in-out;
        pointer-events: auto;

        @include breakpoint("sm"){
            width: 100%;
        }
        &.active{
            transform: translateX(0%);
        }
        .wrapper-menu{
            display: flex;
            flex-direction: column;
            justify-content: space-between;
           
            .menu-logo{
                height: 25px;
                width: 100%;
                margin-left: 20px;
                
                display: flex;
                justify-content: space-between;
                align-items: center;
                a{
                    @include breakpoint("sm"){
                        margin-left: 20px;
                    }
                }
                @include breakpoint("xs"){
                    margin-left: 0;
                }
                .back-button{
                    position: relative;
                    width: 25px;
                    height: 25px;
                    margin-right: 70px;
                    padding: 8px;
                    border-radius: 5px;
                    &:hover{
                        background-color: #EBEBEB;
                    }
                    &::after, &::before{
                        content: '';
                        position: absolute;
                        top: 50%;
                        height: 1px;
                        width: 60%;
                        background-color: rgb(20, 20, 20);
                        border-radius: 15px;
                    }
                    &::after{
                        transform:  rotate(45deg);
                    }
                    &::before{
                        transform: rotate(-45deg);
                    }
                    @include breakpoint("xs"){
                            margin-right: 10px;
                    }
                }
            }
            .links{
                display: flex;
                flex-direction: column;  
                font-size: 20px;
                margin-left: 0!important;
                margin-right: 0!important;
                padding: 0!important;
                font-family: 'VolvoNovum-Medium', Arial, sans-serif;
                >*{
                    height: 50px;
                    display: flex;
                    padding-left: 20px;
                    flex-direction: column;
                    justify-content: center;
                    &:hover{
                        background-color: #FAFAFA
                    }
                }
                .only-mobile{
                    display: none;
                    @include breakpoint("xs"){
                        display: flex;
                    }
                }
            }
            >*{
                padding-top: 20px;
                padding-bottom: 20px;
                border-top: 1px solid rgb(235, 235, 235)
            }
            
            >*:not(.menu-logo){
                margin-left: 20px;
                margin-right: 10px;
            }
            .change-language{
                // pointer-events: auto;
            }
            .soMe {
                display: flex;
                a{
                margin-right: 30px;
                width: 25px;
                    img{
                        width: 100%;
                    }
                }
            }
        }

    }
}