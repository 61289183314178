#scroll__down{
    top: 80vh;
    z-index: 55;
    font-size: 14px;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 180px;
    position: absolute;
}
#scroll__down p{
    color:#FFFFFF;
    margin: 0;
    font-family: "VolvoNovum-Light";
}
.mouse{
    width: 22px;
    height: 40px;
    position: relative;
    border-radius: 100px;
    border: 1px solid rgb(216, 216, 216);
    margin-bottom: 20px;
}
.trackball{
        // content: '';
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
        margin: auto;
        background-color: #D8D8D8;
        width: 4px;
        height: 4px;
        border-radius: 100%;
        animation: trackBallSlide 5s linear infinite;
}

@keyframes trackBallSlide{
    0%{
      opacity: 1;
      transform: translateY(-10px);
    }
    6%{
      opacity: 1;
      transform: scaleY(1.2) translateY(0px);
    }
    14%{
      opacity: 0;
      transform: translateY(0px);
    }
    15%, 19%{
      opacity: 0;
      transform: translateY(-10px);
    }
    28%, 29.99%{
      opacity: 1;
      transform: translateY(-10px);
    }
    30%{
      opacity: 1;
      transform: translateY(-10px);
    }
    36%{
      opacity: 1;
      transform: scaleY(1.2) translateY(0px);
    }
    44%{
      opacity: 0;
      transform: translateY(0px);
    }
    45%, 49%{
      opacity: 0;
      transform: translateY(-10px);
    }
    58%, 59.99%{
      opacity: 1;
      transform: translateY(-10px);
    }
    60%{
      opacity: 1;
      transform: translateY(-10px);
    }
    66%{
      opacity: 1;
      transform: scaleY(1.2) translateY(0px);
    }
    74%{
      opacity: 0;
      transform: translateY(0px);
    }
    75%, 79%{
      opacity: 0;
      transform: translateY(-10px);
    }
    88%, 100%{
      opacity: 1;
      transform: translateY(-10px);
    }
  }