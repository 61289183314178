header{
    // background-color: #000000;
    height: 100vh;
    width: 100%;
    position: fixed;
    overflow: hidden;
    // display: flex;
    // justify-content: center;
    // align-items: center;
    z-index: 300;
    &.mobile{
        position: relative;
    }
    &.lu{
        .noLu{
            display: none; 
            opacity: 0;
            visibility: hidden;
        }
    }
}
.wrapper-line-title{
    overflow: hidden;
    .line-title{
        display: block;
    }
}
.copy-wrapper{
    position: absolute;
    top: 45%;
    left: 50%;
    transform: translate(-50%,-50%);
    width: 80%;
    z-index: 15;
    display: flex;
    flex-direction: column;
    @include breakpoint("xxs"){
        width: 100%;
    }
    .volvo-BMS{
        text-align: center;
        width: 100%;
        color:#FFFFFF;
        font-family: "VolvoNovum-Light";
        line-height: 1.3;
        z-index: 20;
        box-sizing: border-box;
       
      
    }

}
.title{
    font-family: "VolvoNovum-Light";
    color:#FFFFFF;
    font-size: 7vw;
    letter-spacing: .3vw;
    text-align: center;
    line-height: 5.7vw;
    margin: 50px 0;
    z-index: 15;
    margin-left: auto;
    margin-right: auto;
    
   
    @include breakpoint("md"){
        font-size: 50px;
        line-height: 70px;
        top: 45%;
        width: 80%;
    }
    @include breakpoint("xs"){
        width: 100%;
        font-size: 42px;
        line-height: 50px;
    }
}

.wrapper-cars{
    z-index: 15;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;

.wrapper-one-car{
    position: absolute;
    top: 0;
    z-index: 2;
    left: 0;
    &.car-wall{
        @include breakpoint("xl"){
            &#car-XC40{
                left: -200px;
            }
    
        }
        @include breakpoint("sm"){
            &#car-XC40{
                left: -180px;
            }
        }
        &.horizontal{
            width: 400px;
            height: 265px;
        }
        &.vertical{
            width: 300px;
            height: 450px;
        }
    }
    
    
    
    &.animation-visu, &.black-mask{
        width: 270px;
        height: 400px;
        left: 50%;
        top: 50%;
        .wrapper-center{
            position: absolute;
            width: 100%;
            height: 100%;
            transform: translate(-50%,-50%);
        }
        @include breakpoint("xs"){
            top: 45%;
            width: 130px;
            height: 200px;
        }
    }
    

    .one-car, .bgblack{
        width: 100%;
        height: 0%;
        bottom: 0;
        // transform:translate(-50%, -50%);
        overflow: hidden;
        img{
            backface-visibility: hidden;
            -webkit-backface-visibility: hidden;
            transform: rotate(0.1deg);
            // width: 100%;
            @include breakpoint("md"){
                // width: 300px;
            }
        }
        @include breakpoint("md"){
            width: 300px;
        }
        @include breakpoint("xs"){
            width: 130px;
        }
    }
}

    // ANIMATIONS INTRO
    .animation-visu, .black-mask{
        img{
            width: 100%;
            

        }
    }
    #car-1{
        img{
            width: 290px;
        }
    }
    .bgblack{
        transform: scale(1.2);
        background-color: black;
    }
}





.bottom-header{
    display: flex;
    justify-content: space-between;
    width: 94%;
    left: 50%;
    transform: translateX(-50%);
    bottom: 40px; 
    transition: .4s ease-in-out;
    @include breakpoint("md"){
        font-size: 12px;
    }
}
.bottom-header p{
    margin: 0;
    text-transform: uppercase;
    color: #FFFFFF;
    font-family: "VolvoNovum-Light";
}
.bottom-header {
    #countdown, .loading{
        width: 75px;
    }
}
.bottom-header 
    {
        :first-child{
            text-align: left;
        }
        :last-child{
            text-align: right;
        }
    }


#loaded-content{
    color: #FFFFFF;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    // left: 50%;
    // top: 0;
    height: 100vh;
    // transform: translateX(-50%);
    width: 100%;
    // transition-property: opacity;
    transition: opacity .4s ease-in-out;
    position: relative;

    
    .street-photographer{
        margin-top: 42vh;
    }
   
} 
#street-configurator{
    bottom: 50px;
    right: 3%;
    z-index: 55;
    text-align: center;
    @include breakpoint("md"){
        bottom:  25%;
        right: auto;
        left: 50%;
        width: 70%;
        transform: translateX(-50%);
    }
}
.street-photographer{
    color: #FFFFFF;
    text-decoration: underline;
}
#preambule{
    width: 100%;
    height: 100vh;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    display: flex;
    p{
        color: #FFFFFF;
        padding: 0 30px;
        line-height: 1.3;
    }
}