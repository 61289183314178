@mixin breakpoint($class) {
    @if $class == xxsHeight {
      @media (max-height: 750px) { @content; }
    }

    @if $class == xxs {
      @media (max-width: 460px) { @content; }
    }
    @if $class == xs {
      @media (max-width: 520px) { @content; }
    }
  
    @if $class == sm {
      @media (max-width: 768px) { @content; }
    }
  
    @else if $class == md {
      @media (max-width: 992px) { @content; }
    }
  
    @else if $class == lg {
      @media (max-width: 1082px) { @content; }
    }
  
    @else if $class == xl {
      @media (max-width: 1280px) { @content; }
    }
    @else if $class == xxxl {
      @media (max-width: 2280px) { @content; }
    }
  }
  
  
  // SAMPLE
  // @include breakpoint("sm"){
  //   height: 80vh;
  // }
  