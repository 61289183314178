main{
    height: 100vh;
    width: 100%;
    position: relative;
    // background-color: #000;
    @include breakpoint("xxs"){
        height: 90vh;
    }
    &.lu{
        .noLu{
            display: none; 
            opacity: 0;
            visibility: hidden;
        }
    }
    &.mobile{
        #cta-configurator{
            opacity: 1;
            padding: 0 18%;
        }
    }
}
.wrapper-section{
    height: 100vh;
    box-sizing: border-box;
    padding-top: 65px;
    padding-bottom: 50px;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    @include breakpoint("md"){
        // padding-top: 105px;
        // padding-bottom: 100px;
        // justify-content: space-between;
    }
    @include breakpoint("xxsHeight"){
        // padding-top: 85px;
        // padding-bottom: 70px;
    }

}

.cta-offer{
    width: fit-content;
    color: #000;
    background-color: #FFF;
    font-size: 14px;
    position: absolute;
    right: 10px;
    top: 90px;
    @include breakpoint("md"){
        width: 100%;
        right: 0;
        top: 65px;
        padding: 15px 0;
        text-align: center;
        background-color: rgba(255,255,255,0.4);
        border : 1px solid transparent
    }
}
#cta-configurator{
    width: 100%;
    opacity: 0;
    bottom: 0;
    height: 50px;
    box-sizing: border-box;
    padding: 0 50px;
    background-color: #000;
    color:#FFF;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: "VolvoNovum-Light";
    text-decoration: none;
    p{
        position: relative;
        &::after{
            content: '';
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            right: -15px;
            width: 11px;
            height:11px;
            background-image: url('../../img/arrow-white.svg');
        }
    }
    @include breakpoint("xs"){
        font-size: 12px;
    }

    
    
}


