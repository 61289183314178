#countdown{
    position: relative;
    display: flex;
    height: 20px;
    top: 3px;
    color: #FFFFFF;
   .bullet{
    width: 15px;
    height: 15px;
    margin-right: 10px;
    background-color: #FFF;
    border-radius: 100%;
   }
   .remaining-time{
     font-family: "VolvoNovum-Light";  
    font-size: 16px;
    line-height: 16px;
   }

}    