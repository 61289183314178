footer{
    background-color: #E1DFDD;
    position: relative;
    .links{
        padding-top: 100px;
        padding-bottom: 70px;
       margin-left: auto;
       margin-right: auto;
        .footer-line{
            text-align: left;
            transform-origin: 0px 50% 0px;
            position: absolute;
            top: 0;
            // left: 50%;
            // transform: translateX(-50%);
            height: 1px;
            background-color: black;
            width: 98%;
            @include breakpoint("sm"){
                width: 87%;

            }
        }
        @include breakpoint("xs"){
            padding-top: 50px;
            padding-bottom: 150px;
            width: 90%;
        }
        .wrapper-link{
            font-family: "VolvoNovum-Light";
            font-size: 42px;
            // padding: 20px 0;
            overflow: hidden;
            padding: 0 1%;
            position: relative;
            @include breakpoint("sm"){
                font-size: 28px;
            }
            @include breakpoint("xs"){
                font-size: 22px;
                // padding: 0 20px;
            }
            @include breakpoint("xxs"){
                font-size: 18px;
                // padding: 0 20px;
            }
            a{
                text-decoration: none;
                color: #000000;
                display: block;
                padding: 20px 0;

            }
            
            .footer-arrow{
                content: '';
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                right: 15px;
                width: 23px;
                height: 23px;
                background-image: url('../../img/arrow-black.svg');
            }
            &:hover{
                background-color: darken(#E1DFDD, 10%);
            }
        }
    }
    .btn-configurator-footer{
        right: 3%;
        background-color: #000;
        border: 1px solid #000;
        bottom: 20px;
    }
}