#slider{
    position: absolute;
    top: 50%;
    left: 9%;
    width: 55px;
    transform: translateY(-50%);
    font-family:"VolvoNovum-Regular" ;
    font-size: 10px;
    text-transform: uppercase;
    @include breakpoint("xl"){
        left: 20px;
    }
    .list-cars, .current-case{
        display: block;
        width: 100%;
        text-align: center;
    }
    .list-cars{
        text-align: center;
        line-height: 15px;
        height: 80px;
        &.prev-cars{
            :not(:first-child){
                display: none;
            }
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
        }
        &.next-cars{
            :first-child{
                display: none;
            }
            
        }
        .hide{
            display: none !important;
        }
        .show{
            display: block !important;
        }
    }
    .line-slider{
        height: 70px;
        margin: 5px 0px;
        @include breakpoint("md"){
           height: 50px;
         }
        // background-color: red;
        position: relative;
        &::before{ // DASH LINE
            content: ' ';
            position: absolute;
            left: 50%;
            top: 0;
            transform: translateX(-50%);
            height: 100%;
            width: 0px;
            border : 1px dashed #000;
        }
        .solid-line{
            content: ' ';
            position: absolute;
            left: 50%;
            height: 100%; 
            bottom: 0;
            transform: translateX(-50%);
            width: 0px;
            border : 1px solid #000;
        }
        .tracker{
            position: absolute;
            bottom: 100%;
            left: 50%;
            transform: translate(-50%, 50%);
            width: 8px;
            height: 8px;
            z-index: 2;
            background-color: #000;
            border-radius: 5px;
        }
    }
}