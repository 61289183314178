.content-wrapper{
    display: flex;
    flex-direction: column;
    position: relative;
    width: 50%;
    max-width: 800px;
    max-height: 600px;
    @include breakpoint("md"){
        width: 80%;
    }
    @include breakpoint("xxs"){
        width: 100%;
    }
    @include breakpoint("xl"){
        max-width: 600px;
        max-height: 450px;
    }

}
.big-wrapper{
   
    position: relative;
 
    .copyright-photo{
        font-family: 'VolvoNovum-Light';
        font-size: 9px;
        top: 0;
        left: -5px;
        position: absolute;
        transform-origin: 0 0;
        transform: rotate(90deg);
        // transform: rotate(-90deg) translateY(-100%);
        @include breakpoint("sm"){
            font-size: 8px;
            top: -15px;
            left: 0px;
            margin-top: 5px;
            transform: rotate(0deg);

        }
        @include breakpoint("xs"){
            font-size: 7px;
        }
    }
    
}
.wrapper-cases{
    // height: 0;
    width: 100%;
    height: 100%;
    // bottom: 0;
    background-color: #000;
    // padding: 25px;
    // scroll-snap-type: y proximity;
    z-index: 150;
    position: absolute;
    overflow: hidden;
    // @include breakpoint("xl"){
    //     height: 440px;
    // }
    // @include breakpoint("md"){
    //     height: 340px;
    // }
    // @include breakpoint("xs"){
    //     height: 240px;
    // }
    
    
}
// .wrapper-arrows{
//     display: none !important;
//     visibility: hidden;
//     opacity: 0;
//     @include breakpoint("lg"){
//         display: flex !important;
//         visibility: visible;
//         opacity: 1;
//     }
// }
main{
    height: 600vh;
    &.mobile{
        height: 100vh;
    }
    
}
.wrapper-section{
    top: 0vh;
    position: fixed;
    width: 100%;
    &.mobile{
        position: static;
    }
}
.all-cases{
    width: 100%;
    height: 100%;
    // top: 0;
    // left: 0 !important;
    overflow: hidden;
    position: relative;

    .wrapper-slider-img{
        // visibility: hidden;
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 0;
        // max-width: 500px;
        overflow: hidden;
        transform-origin: bottom;
        &:first-child{
          height: 100%;
          // z-index: 1;
        }
        a{
            display: inherit;
        }
        a, img{
            width: 100%;
            height: 100%;
        }
        img{
          height: 100%;
          width: 100%;
          object-fit: cover;
          transform-origin: top;
        }

        video{
            object-fit: cover;
            height: 100%;
        }
        @include breakpoint("lg"){
            margin-bottom: 3px;
        }
    }
    
    
}

.video{
    display: flex;
    // justify-content: center;
    // align-items: center;
    >div{
        width: 100%;
    }
    @include breakpoint("lg"){
        margin-bottom: 0px!important;
    }
    // &:after{
    //     content: ' ';
    //     position: absolute;
    //     top: 0;
    //     left: 0;
    //     width: 100%;
    //     height: 100%;
    //     opacity: 0;
    //     pointer-events: none;
    //     z-index: 10;
    // }
}
// RESPONSIVE CASES
.wrapper-info, .big-wrapper, .wrapper-slider-img, video{
    width: 100%;
    height: 60vh;
    max-width: 800px;
    max-height: 600px;
    // height: 560px;
    // @include breakpoint("xl"){
    //     width: 750px;
    //     height: 525px;
    // }
    // @include breakpoint("lg"){
    //     width: 680px;
    //     height: 476px;
    // }
    @include breakpoint("xl"){
        max-width: 600px;
        max-height: 450px;
    }
    @include breakpoint("sm"){
    //     width: 450px;
    //     height: 315px;
           height: 30vh;
    }
    // @include breakpoint("xs"){
    //     width: 380px;
    //     height: 266px;
    // }
    // @include breakpoint("xxs"){
    //     width: 100%;
    //     height: 280px;
    // }
    // @include breakpoint("xxsHeight"){
    //     height: 220px;
    // }
}


.big-wrapper, .wrapper-info{
    @include breakpoint("xxs"){
        width: calc(100% - 30px)!important;
        align-self: flex-end !important;
    }
}
.wrapper-info{
    height: auto!important;
    position: relative;
    padding-top: 30px;
}
.wrapper-arrows{
    position: absolute;
    top: 0;
    left: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    pointer-events: none;
    >*{
        pointer-events: auto;

        width: 40px;
        height: 40px;
        z-index: 999;
    }
    .prev{
        top:10px;
    }
    .next{
        bottom: 10px;
    }
    @include breakpoint("xxs"){
        left: auto;
        right: 30px;
        top: 115%
    }
}
.info-case{
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-family: 'VolvoNovum-Light';
    font-size: 13px;
    box-sizing: border-box;
    position: relative;
    z-index: 2;
    pointer-events: none;
    >*:not(.cta-conditions){
        width: 20%;
        margin-right: 5%;
    }

    .model{
        font-family: 'VolvoNovum-Medium';
        width: 23%;
        margin-right: 2%;
    }
    .description{
        position: relative;
    }
    .description::after{
        content: ' ';
        position: absolute;
        top: -10%;
        right: 0;
        height: 120%;
        width: 1px;
        background-color: #000000;
        @include breakpoint("sm"){
            display: none;
        }
    }
    .price{
        width: 24%;
        margin-right: 1%;
        span{
            font-family: 'VolvoNovum-Medium';
        }
    }
    .cta-conditions{
        pointer-events: auto;
        height: 40px;
        width: 25%;
        padding: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        border: 1px solid #000;
        color: #FFF;
        background-color: #000;
        font-size: 13px;
        box-sizing: border-box;
        @include breakpoint("xxs"){
            width: calc(100% - 30px)!important;

        }

    }
    @include breakpoint("sm"){
        flex-wrap: wrap;
        >*{
            width: 50% !important;
            margin-right: 0!important;
            margin-bottom: 15px;
        }
    }
    @include breakpoint("xxs"){
        >*:not(.cta-conditions){
            width: 100% !important;
            margin-right: 0!important;
            margin-bottom: 15px;
        }
    }
}

.info-video{
    width: 75%;
    opacity: 0;
    font-size: 26px;
    font-family: 'VolvoNovum-Light';
    position: absolute;
    left: 0;
    top: 30px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    @include breakpoint("xs"){
        font-size: 18px;
        width: 80%;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
    }
    // .btn-video{
    //     border: 1px solid #000;
    //     color: #000;
    //     font-size: 12px;
    //     width: 190px;
    //     height: 40px;
    //     display: flex;
    //     justify-content: center;
    //     align-items: center;
    //     padding: 0;
    //     position: relative;
    //     @include breakpoint("xs"){
    //         margin-top: 20px;
    //     }
    //     .icon-play-video{
    //         width: 11px;
    //         height: 14px;
    //         margin-right: 10px;
    //         img{
    //             position: absolute;
    //             width: 11px;
    //             height: 14px;
    //             display: none;
    //             &.active{
    //                 display: block;
    //             }
               
    //         }
    //     }
    // }

}