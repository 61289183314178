.abs{
    position: absolute;
}

.rel{
    position: relative;
}
.App{
    background-color: #000;
    // overflow:overlay;
    width: 100%;
    position: fixed; 
    overflow: hidden;
    overflow-y:scroll;
    &.unlock{
        position: static;
        // height: 100vh;
        overflow-y: auto;
    }
}
.App::-webkit-scrollbar { width: 0 !important }
.App { overflow: -moz-scrollbars-none; }
.App{ -ms-overflow-style: none; }
body, .App, html{
    scrollbar-width: none;
}
.btn{
    border: 1px solid #FFF;
    padding: 10px 20px;
    padding-bottom: 13px;
    color: #FFF;
    text-decoration: none;
    font-size: 16px;
}
.tcenter{
    text-align: center;
}

.fade-appear{
    opacity: 0 !important;
}
.fade-appear-done{
    opacity: 1!important;
}
html{
    width: 100%;
}
body::-webkit-scrollbar { width: 0 !important }
body { overflow: -moz-scrollbars-none; }
body{ -ms-overflow-style: none; }

body{
    // overflow-x: hidden;
    overflow-y: auto!important;
    width: 100%;
}
body.cursor--active, body .cursor--active a, body .cursor--active button, html.cursor--active, html .cursor--active a, html .cursor--active button, body, a{
    cursor: none;
}
#CookiebotWidget, #CybotCookiebotDialog{
    cursor: auto !important;
    >*{
    cursor: auto !important;

    }
    .CybotCookiebotDialogBodyButton{
        cursor: pointer;
    }
}
.loading{
    opacity: 0;
}
.isLoaded{
    opacity: 1;
    transition: opacity .4s ease-in-out;
}
#custom-mouse{
    width: 10px;
    height: 10px;
    border-radius: 100%;
    background-color: #FFF;
    position: fixed;
    z-index: 999995;
    display: block;
    transform: rotate(0.1deg) translate(-50%,-50%);
    justify-content: center;
    align-items: center;
    display: block;
    pointer-events: none;
    transition-property: width, height, background-color;
    transition-duration: .3s;
    transition-timing-function: ease-out;
    &.bigger{
        width: 80px;
        height: 80px;
        .content-custom-mouse{
            display: flex;
        }
    }
    &.black{
        background-color: #000;
    }
    .content-custom-mouse{
        // display: flex;
        display: none;
        justify-content: center;
        align-items: center;
        line-height: 12px;
        width: 100%;
        height: 100%;
        font-size: 10px;
        position: absolute;
        top: 0%;
        left: 50%;
        transform: rotate(0.1deg) translate(-50%);
        text-align: center;
        text-transform: uppercase;
        font-family: "VolvoNovum-Regular";
    }
    @include breakpoint("md"){
        display: none;
    }
}